import { useCustomer } from '@customer/composables/useCustomer'
import type {
  CategoryFiltersQueryVariables,
  CategoryListQueryVariables,
  CategoryQueryVariables,
} from '#gql'

export function useCatalogQueries() {
  const { isLoggedIn } = useCustomer()

  const getCategory = async (variables: CategoryQueryVariables) => {
    if (isLoggedIn.value) {
      return await GqlCategoryCustomer(variables)
    }
    else {
      return await GqlCategory(variables)
    }
  }

  const getCategoryList = async (variables: CategoryListQueryVariables) => {
    if (isLoggedIn.value) {
      return await GqlCategoryListCustomer(variables)
    }
    else {
      return await GqlCategoryList(variables)
    }
  }

  const categoryFilters = async (variables: CategoryFiltersQueryVariables) =>
    await GqlCategoryFilters(variables)

  return {
    getCategory,
    getCategoryList,
    categoryFilters,
  }
}
