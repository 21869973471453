import { defineStore } from 'pinia'
import replaceConsentVariables from '@base/utils/replaceConsentVariable'
import { mapStoreConfigData } from '../helpers/mapStoreConfigData'
import { mapCurrentCurrencyData } from '../helpers/mapCurrentCurrencyData'

import type {
  ConsentsArea,
  RawlplugConsent,
  StoreConfigState,
} from './storeConfig.types'

export const useStoreConfigStore = defineStore('storeConfig', {
  state: (): StoreConfigState => ({
    storeConfig: {
      centralOfficeData: null,
      customerServiceData: null,
      checkoutData: null,
      enableReportErrorModal: false,
      locale: '',
    },
    currency: {
      currencyData: null,
    },
    consents: [],
    _states: {
      pending: false,
      error: null,
    },
  }),
  actions: {
    async getStoreConfig() {
      this._states.pending = true
      this._states.error = null

      const { data, error } = await useAsyncData('storeConfig', () =>
        GqlStoreConfig())
      if (error) {
        this._states.error = error.value?.message ?? null
        this._states.pending = false
      }
      if (data.value) {
        const { storeConfig, currency, rawlplugConsents } = data.value

        if (storeConfig) {
          this.storeConfig = mapStoreConfigData(storeConfig)
        }

        if (currency) {
          this.currency = mapCurrentCurrencyData(currency)
        }

        this.consents = rawlplugConsents
        this._states.pending = false
        return this.storeConfig
      }
    },
    getConsents(section: ConsentsArea): RawlplugConsent[] {
      let consents: RawlplugConsent[] = []
      this.consents?.forEach((consent) => {
        if (consent?.section === section && consent?.consents) {
          consents = consent.consents
        }
      })

      // replace variables in consents with real values
      consents.map((consent) => {
        if (consent?.description) {
          consent.description = replaceConsentVariables(consent.description)
        }
        if (consent?.moreDescription) {
          consent.moreDescription = replaceConsentVariables(
            consent.moreDescription,
          )
        }
        consent?.children?.map((child) => {
          if (child?.description) {
            child.description = replaceConsentVariables(child.description)
          }
          if (child?.moreDescription) {
            child.moreDescription = replaceConsentVariables(
              child.moreDescription,
            )
          }
          return child
        })
        return consent
      })
      return consents ?? []
    },
    // allows to update maintenance mode status client side
    updateMaintenanceMode({ status, message }: { status: boolean, message: string }) {
      this.storeConfig.rawlplug_ifs_general_enable_maintenance = status
      this.storeConfig.rawlplug_ifs_general_html_description = message
    },
  },
  getters: {
    storeConfigData: state => state.storeConfig,
    centralOfficeData: state => state.storeConfig.centralOfficeData,
    customerServiceData: state => state.storeConfig.customerServiceData,
    checkoutData: state => state.storeConfig.checkoutData,
    currencyData: state => state.currency.currencyData,
    maintenanceMode: (state) => {
      return {
        isMaintenanceEnabled: state.storeConfig.rawlplug_ifs_general_enable_maintenance as boolean,
        maintenanceMessage: state.storeConfig.rawlplug_ifs_general_html_description as string,
      }
    },
  },
})
