import { default as _91_46_46_46slug_933EIH54d0YoMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-cms/pages/[...slug].vue?macro=true";
import { default as addressespspUmNGE3QMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/addresses.vue?macro=true";
import { default as companywwcD0ruSwBMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/company.vue?macro=true";
import { default as contactVwVYgEI66RMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/contact.vue?macro=true";
import { default as indexR2aO2lg3ohMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/index.vue?macro=true";
import { default as listsupIb3qH8DXMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/lists.vue?macro=true";
import { default as _91variantId_93hYEJHGfVaYMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/offer/[id]/[variantId].vue?macro=true";
import { default as indexAYBrhcziq5Meta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/offers/index.vue?macro=true";
import { default as _91_46_46_46slug_93oCQbd4EeFvMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue?macro=true";
import { default as orders3ToGDmEWCeMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/orders.vue?macro=true";
import { default as payments_45and_45billing9xx0M1Fm8dMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue?macro=true";
import { default as add_45userIkuF5j3zIjMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue?macro=true";
import { default as editQewfVmkf4LMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue?macro=true";
import { default as indexj40LRxo1yNMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue?macro=true";
import { default as newHcuDAroNQbMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue?macro=true";
import { default as editnC7A4jFU61Meta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue?macro=true";
import { default as indexNB5jA52D8RMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue?macro=true";
import { default as profile8157izDHwPMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/profile.vue?macro=true";
import { default as accountruARAOskR3Meta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account.vue?macro=true";
import { default as _91slug_93zXeyJK8pPXMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue?macro=true";
import { default as categorycQ9erMGHOaMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/category.vue?macro=true";
import { default as carti7nD7WKYz3Meta } from "/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue?macro=true";
import { default as delivery_45and_45paymentDgl1QpM6ccMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue?macro=true";
import { default as summaryIClAwcVXshMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue?macro=true";
import { default as checkoutbFxiZIPitHMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/checkout.vue?macro=true";
import { default as createPasswordOTKE9a6BGGMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue?macro=true";
import { default as resetPasswordWifxIDvGZKMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue?macro=true";
import { default as indexzr4yIvGplzMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/event/index.vue?macro=true";
import { default as indexcKMDekR9IqMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/newsletter/index.vue?macro=true";
import { default as productneAhbbRO81Meta } from "/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/product.vue?macro=true";
import { default as registerHbJoqI7GhoMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/register.vue?macro=true";
import { default as resolveoU1UY6zmxOMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/resolve.vue?macro=true";
import { default as cms5OWFcWIDVvMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/search/cms.vue?macro=true";
import { default as storeSuk6o1Fc2jMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/search/store.vue?macro=true";
import { default as searchKu1Py1uRNvMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/search.vue?macro=true";
import { default as DescriptionSectionTWAPYPG1H5Meta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue?macro=true";
import { default as HeroSection06zwAHmM6hMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue?macro=true";
import { default as indexsvYEjzMD22Meta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue?macro=true";
import { default as ChooseClientAHq1raRMPxMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue?macro=true";
import { default as ChooseClientElementkmixgXQgHKMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue?macro=true";
import { default as ClientsElementVOjDOlz6R9Meta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue?macro=true";
import { default as ClientsSectionFXyXqKJRPTMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue?macro=true";
import { default as HeroHeaderXTFr2YQUOvMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue?macro=true";
import { default as goToJoinRawlplugSectionI83Ks431w0Meta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts?macro=true";
import { default as JoinRawlplugAHQS2z5jJ1Meta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue?macro=true";
import { default as JoinRawlplugFormtvJs8d0yvYMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue?macro=true";
import { default as JoinRawlplugModalGeKOaxOR33Meta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue?macro=true";
import { default as JoinRawlplugStickyEUxr4g5PVSMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue?macro=true";
import { default as SlideCtaHnqalMIRdpMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue?macro=true";
import { default as SlideLeftl0pRQwwS1nMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue?macro=true";
import { default as SlideRightpvAfUHiZhqMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue?macro=true";
import { default as SliderPaginationVHr4NsghN8Meta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue?macro=true";
import { default as SliderSectionNsHUVBJMHpMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue?macro=true";
import { default as slidesDD1jezG7bbMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts?macro=true";
import { default as disableAutoScrollOnLastSectionPcoKRAQoTpMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts?macro=true";
import { default as usePaginationDtfxOsTx1UMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts?macro=true";
import { default as useStyleWithHeaderHeightSS85ilv7Z4Meta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts?macro=true";
import { default as index5odYSSb1ejMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/index.vue?macro=true";
import { default as constantsfK8Qdg1zFxMeta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js?macro=true";
import { default as FullPageF3kyKLRDf3Meta } from "/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue?macro=true";
import { default as T3PageMsZNkmWEEpMeta } from "/home/ecomadm/app/releases/201/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_933EIH54d0YoMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-cms/pages/[...slug].vue")
  },
  {
    name: "account",
    path: "/:locale()/account",
    meta: accountruARAOskR3Meta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account.vue"),
    children: [
  {
    name: addressespspUmNGE3QMeta?.name ?? "locale-account-addresses",
    path: "addresses",
    meta: addressespspUmNGE3QMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/addresses.vue")
  },
  {
    name: companywwcD0ruSwBMeta?.name ?? "locale-account-company",
    path: "company",
    meta: companywwcD0ruSwBMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/company.vue")
  },
  {
    name: contactVwVYgEI66RMeta?.name ?? "locale-account-contact",
    path: "contact",
    meta: contactVwVYgEI66RMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/contact.vue")
  },
  {
    name: "locale-account",
    path: "",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/index.vue")
  },
  {
    name: listsupIb3qH8DXMeta?.name ?? "locale-account-lists",
    path: "lists",
    meta: listsupIb3qH8DXMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/lists.vue")
  },
  {
    name: "offer",
    path: "offer/:id/:variantId",
    meta: _91variantId_93hYEJHGfVaYMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/offer/[id]/[variantId].vue")
  },
  {
    name: indexAYBrhcziq5Meta?.name ?? "locale-account-offers",
    path: "offers",
    meta: indexAYBrhcziq5Meta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/offers/index.vue")
  },
  {
    name: "locale-account-order-slug",
    path: "order/:slug+",
    meta: _91_46_46_46slug_93oCQbd4EeFvMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue")
  },
  {
    name: orders3ToGDmEWCeMeta?.name ?? "locale-account-orders",
    path: "orders",
    meta: orders3ToGDmEWCeMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/orders.vue")
  },
  {
    name: payments_45and_45billing9xx0M1Fm8dMeta?.name ?? "locale-account-payments-and-billing",
    path: "payments-and-billing",
    meta: payments_45and_45billing9xx0M1Fm8dMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue")
  },
  {
    name: "locale-account-permissions-add-user",
    path: "permissions/add-user",
    meta: add_45userIkuF5j3zIjMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue")
  },
  {
    name: "locale-account-permissions-roles-id-edit",
    path: "permissions/roles/:id()/edit",
    meta: editQewfVmkf4LMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue")
  },
  {
    name: "locale-account-permissions-roles",
    path: "permissions/roles",
    meta: indexj40LRxo1yNMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue")
  },
  {
    name: "locale-account-permissions-roles-new",
    path: "permissions/roles/new",
    meta: newHcuDAroNQbMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue")
  },
  {
    name: "locale-account-permissions-users-id-edit",
    path: "permissions/users/:id()/edit",
    meta: editnC7A4jFU61Meta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue")
  },
  {
    name: indexNB5jA52D8RMeta?.name ?? "locale-account-permissions-users",
    path: "permissions/users",
    meta: indexNB5jA52D8RMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue")
  },
  {
    name: profile8157izDHwPMeta?.name ?? "locale-account-profile",
    path: "profile",
    meta: profile8157izDHwPMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/account/profile.vue")
  }
]
  },
  {
    name: "category",
    path: "/:path/c/",
    meta: categorycQ9erMGHOaMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/category.vue"),
    children: [
  {
    name: "category",
    path: ":slug+",
    meta: _91slug_93zXeyJK8pPXMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue")
  }
]
  },
  {
    name: checkoutbFxiZIPitHMeta?.name ?? "locale-checkout",
    path: "/:path/checkout/",
    meta: checkoutbFxiZIPitHMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/checkout.vue"),
    children: [
  {
    name: carti7nD7WKYz3Meta?.name ?? "locale-checkout-cart",
    path: "/:path/checkout/cart",
    meta: carti7nD7WKYz3Meta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue")
  },
  {
    name: "checkout-delivery-and-payment",
    path: "/:path/checkout/delivery-and-payment",
    meta: delivery_45and_45paymentDgl1QpM6ccMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue")
  },
  {
    name: "checkout-summary",
    path: "/:path/checkout/summary",
    meta: summaryIClAwcVXshMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue")
  }
]
  },
  {
    name: "locale-customer-account-createPassword",
    path: "/:locale()/customer/account/createPassword",
    meta: createPasswordOTKE9a6BGGMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue")
  },
  {
    name: "locale-customer-account-resetPassword",
    path: "/:locale()/customer/account/resetPassword",
    meta: resetPasswordWifxIDvGZKMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue")
  },
  {
    name: "locale-event",
    path: "/:locale()/event",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/event/index.vue")
  },
  {
    name: "locale-newsletter",
    path: "/:locale()/newsletter",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/newsletter/index.vue")
  },
  {
    name: "product",
    path: "/:path/p/:slug",
    meta: productneAhbbRO81Meta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/product.vue")
  },
  {
    name: registerHbJoqI7GhoMeta?.name ?? "locale-register",
    path: "/:locale()/register",
    meta: registerHbJoqI7GhoMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-customer/pages/[locale]/register.vue")
  },
  {
    name: "ecom-redirect-resolve",
    path: "/:locale/r/:type/:slug",
    meta: resolveoU1UY6zmxOMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/resolve.vue")
  },
  {
    name: searchKu1Py1uRNvMeta?.name ?? "locale-search",
    path: "/:path/search",
    meta: searchKu1Py1uRNvMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/search.vue"),
    children: [
  {
    name: cms5OWFcWIDVvMeta?.name ?? "locale-search-cms",
    path: "/:path/search/cms",
    meta: cms5OWFcWIDVvMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/search/cms.vue")
  },
  {
    name: storeSuk6o1Fc2jMeta?.name ?? "locale-search-store",
    path: "/:path/search/store",
    meta: storeSuk6o1Fc2jMeta || {},
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-ecom/pages/[locale]/search/store.vue")
  }
]
  },
  {
    name: "locale-technical-helpdesk-components-DescriptionSection",
    path: "/:locale()/technical-helpdesk/components/DescriptionSection",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue")
  },
  {
    name: "locale-technical-helpdesk-components-HeroSection",
    path: "/:locale()/technical-helpdesk/components/HeroSection",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue")
  },
  {
    name: "locale-technical-helpdesk",
    path: "/:locale()/technical-helpdesk",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClient",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClient",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClientElement-ChooseClientElement",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsElement-ClientsElement",
    path: "/:locale()/welcome/components/ClientsSection/ClientsElement/ClientsElement",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsSection",
    path: "/:locale()/welcome/components/ClientsSection/ClientsSection",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue")
  },
  {
    name: "locale-welcome-components-HeroHeader-HeroHeader",
    path: "/:locale()/welcome/components/HeroHeader/HeroHeader",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-goToJoinRawlplugSection",
    path: "/:locale()/welcome/components/JoinRawlplug/goToJoinRawlplugSection",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplug",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplug",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugForm",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugForm",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugModal",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugModal",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugSticky",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugSticky",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideCta",
    path: "/:locale()/welcome/components/slider/SlideCta",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideLeft",
    path: "/:locale()/welcome/components/slider/SlideLeft",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideRight",
    path: "/:locale()/welcome/components/slider/SlideRight",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderPagination",
    path: "/:locale()/welcome/components/slider/SliderPagination",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderSection",
    path: "/:locale()/welcome/components/slider/SliderSection",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue")
  },
  {
    name: "locale-welcome-components-slider-slides",
    path: "/:locale()/welcome/components/slider/slides",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts")
  },
  {
    name: "locale-welcome-composables-disableAutoScrollOnLastSection",
    path: "/:locale()/welcome/composables/disableAutoScrollOnLastSection",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts")
  },
  {
    name: "locale-welcome-composables-usePagination",
    path: "/:locale()/welcome/composables/usePagination",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts")
  },
  {
    name: "locale-welcome-composables-useStyleWithHeaderHeight",
    path: "/:locale()/welcome/composables/useStyleWithHeaderHeight",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts")
  },
  {
    name: "locale-welcome",
    path: "/:locale()/welcome",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/index.vue")
  },
  {
    name: "locale-welcome-vendors-fullpage-constants",
    path: "/:locale()/welcome/vendors/fullpage/constants",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js")
  },
  {
    name: "locale-welcome-vendors-fullpage-FullPage",
    path: "/:locale()/welcome/vendors/fullpage/FullPage",
    component: () => import("/home/ecomadm/app/releases/201/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue")
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/home/ecomadm/app/releases/201/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue")
  }
]