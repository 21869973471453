import type { ProductAttributeSortInput, SortFields } from '#gql'

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface SortOption {
  label: string
  value: string
}

export interface SortParameters {
  selected: string | { label: string, value: any }
  options: SortOption[]
}

export interface UseSort {
  SortByDefaults: () => SortParameters
  createSortOptions: ({ sortFields, addDefaultSortOption }: { sortFields: SortFields, addDefaultSortOption: boolean }) => SortParameters
  createProductAttributeSortInput: (sortString: ProductAttributeSortInput | string) => ProductAttributeSortInput
}
