import { useCustomer } from '@customer/composables/useCustomer'
import type {
  CatalogProductsCustomerQueryVariables,
  CatalogProductsQueryVariables,
  GetProductByCategoryIdQueryVariables,
  MagentoCategoryProductsListLimitedQueryVariables,
  MagentoCategoryProductsListQueryVariables,
  ProductQueryVariables,
  RelatedProductsQueryVariables,
  SearchSuggestionsProductsCustomerQueryVariables,
  SearchSuggestionsProductsQueryVariables,
  UpsellProductsQueryVariables,
} from '#gql'

function useProductQueries() {
  const { isLoggedIn } = useCustomer()

  const product = async (variables: ProductQueryVariables) =>
    await GqlProduct(variables)

  const relatedProducts = async (variables: RelatedProductsQueryVariables) =>
    await GqlRelatedProducts(variables)

  const upsellProducts = async (variables: UpsellProductsQueryVariables) =>
    await GqlUpsellProducts(variables)

  const searchSuggestionsProducts = async (variables: SearchSuggestionsProductsQueryVariables | SearchSuggestionsProductsCustomerQueryVariables) => {
    if (isLoggedIn.value) {
      return await GqlSearchSuggestionsProductsCustomer(variables)
    }
    else {
      return await GqlSearchSuggestionsProducts(variables)
    }
  }

  const getCatalogProducts = async (variables: CatalogProductsQueryVariables | CatalogProductsCustomerQueryVariables) => {
    if (isLoggedIn.value) {
      const { products } = await GqlCatalogProductsCustomer(variables) || {}
      return products
    }
    else {
      const { products } = await GqlCatalogProducts(variables) || {}
      return products
    }
  }

  const getCatalogAggregations = async (variables: CatalogProductsQueryVariables) => {
    const { products } = await GqlAggregations(variables) || {}
    return products
  }

  const getProductByCategoryId = async (variables: GetProductByCategoryIdQueryVariables) =>
    await GqlGetProductByCategoryId(variables)

  const magentoCategoryProductsList = async (variables: MagentoCategoryProductsListQueryVariables) =>
    await GqlMagentoCategoryProductsList(variables)

  const magentoCategoryProductsListLimited = async (variables: MagentoCategoryProductsListLimitedQueryVariables) =>
    await GqlMagentoCategoryProductsListLimited(variables)

  return {
    product,
    relatedProducts,
    upsellProducts,
    searchSuggestionsProducts,
    getCatalogProducts,
    getCatalogAggregations,
    getProductByCategoryId,
    magentoCategoryProductsList,
    magentoCategoryProductsListLimited,
  }
}

export { useProductQueries }
