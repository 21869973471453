import { useStoreConfig } from '@ecom/composables/useStoreConfig/useStoreConfig'
import { useCustomer } from '@customer/composables/useCustomer'
import { useDrawer } from '@base/components/AppDrawer/useDrawer'

/**
 * This plugin is responsible for preventing user actions on arleady laoded FE app when the app is in maintenance mode.
 */
export default defineNuxtPlugin({
  name: 'maintenance-mode',
  parallel: true,
  async setup(nuxtApp) {
    const { hook } = nuxtApp
    const { customerData, isLoggedIn, logOutCustomer } = useCustomer()
    const { updateMaintenanceMode } = useStoreConfig()
    const { toggleAppAccount } = useDrawer()

    hook('t3:onResponse', context => verifyMaintanceMode(context.response.status))
    hook('ecom:rest:onResponse', context => verifyMaintanceMode(context.response.status))
    hook('ecom:graphql:onError', error => verifyMaintanceMode(error.statusCode))

    /**
     * If there is 503 status code in response, reload the page.
     * After hard reload in browser nginx will serve maintenance page.
     * 503 status code is returned from Magento 2 API or TYPO3 API if there is upgrade/deployment in progress.
     */
    function verifyMaintanceMode(statusCode: number | undefined) {
      if (statusCode === 503) {
        return window.location.reload()
      }
    }

    /**
     * "soft maintenance mode" - when logging in is disabled for customers in Magento 2 admin panel
     * on every page change or user action (e.g. add to cart) customerData is fetched from Magento 2 API
     * and then maintenance mode data (from SSR) is updated in storeConfig
     */
    const clientSideMaintenanceModeData = computed(() => customerData.value?.maintenance)
    watch(clientSideMaintenanceModeData, async (maintenanceModeData) => {
      if (typeof maintenanceModeData?.enabled === 'boolean') {
        updateMaintenanceMode({
          status: maintenanceModeData?.enabled || false,
          message: maintenanceModeData?.description || '',
        })
      }

      if (maintenanceModeData?.enabled && isLoggedIn.value) {
        await logOutCustomer({
          redirectTo: false,
          userInitializedAction: false,
        })
        toggleAppAccount(true)
      }
    }, {
      immediate: true,
    })
  },
})
