import { type JwtPayload, jwtDecode } from 'jwt-decode'
import { useIndividualProductDataStore } from '@ecom/stores/individual'
import { useAppNav } from '@base/components/AppNav/useAppNav'
import { useCustomerCreditHold } from '@ecom/composables/customer/useCustomerCreditHold/useCustomerCreditHold'
import { useCustomer } from '@customer/composables/useCustomer'
import { regionalStorageName } from '@base/composables/useRegionalStorage'

/**
 * Refresh customer token if it's about to expire
 * or redirect to login page if token is expired
 */
export default defineNuxtPlugin(() => {
  const { initialData, pageData, getPage, getInitialData } = useT3Api()
  const { getPathWithLocale, currentLocale } = useT3i18n()
  const { initNavigation } = useAppNav()
  const {
    isLoggedIn,
    refreshCustomerToken,
    logOutCustomer,
    refreshSectionData,
    _states,
    customerData,
    customerToken,
  } = useCustomer()
  const { toggleSystemNotificationOnCreditHold } = useCustomerCreditHold()
  const { currentRoute } = useRouter()
  const error = useError()

  const userNavigatedUrl = ref(null)

  async function verifySession() {
    if (error.value) {
      // don't verify session on error pages
      return
    }

    const redirectUrl = userNavigatedUrl.value
      ? userNavigatedUrl.value
      : currentRoute.value.fullPath

    if (!customerData.value && !_states.pending) {
      return await logOutCustomer({
        redirectTo: redirectUrl,
      })
    }

    if (customerToken.value) {
      const jwtData = jwtDecode<JwtPayload>(customerToken.value)
      const now = Date.now()
      // if token doesn't have expiration date, log out customer
      if (!jwtData.exp) {
        return await logOutCustomer()
      }

      const exp = jwtData.exp * 1000
      const hoursToExpire = _hoursSince(exp)
      if (now >= exp) {
        return await logOutCustomer({
          redirectTo: redirectUrl,
        })
      }
      if (hoursToExpire <= 2) {
        await refreshCustomerToken()

        // refresh customerData if it's not available in localStorage
        if (!customerData.value) {
          await refreshSectionData()
        }
      }

      /**
       * Refresh customerData if it's older than 1 hour.
       * "messages" field is used, because it is refreshed on every route.
       * Thanks to this cartId is updated more frequently.
       */
      const lastSectionDataTimestamp = (customerData.value?.messages?.data_id || 0) * 1000
      if (lastSectionDataTimestamp && _hoursSince(lastSectionDataTimestamp) > 1) {
        await refreshSectionData({
          lazy: true,
          sections: ['cart', 'messages', 'maintenance'],
        })
      }

      userNavigatedUrl.value = null
    }
  }

  /**
   * Verify session when token cookie or customerData in localStorage will be removed
   * (only when browser tab is active)
   */
  watch([customerToken, customerData], async () => {
    if ((!customerToken.value || !customerData.value) && !document.hidden) {
      await verifySession()
    }
  })

  /**
   * Reset individualProductData store when user navigates to different page
   */
  const individualProductDataStore = useIndividualProductDataStore()
  // TODO: afterEach triggers twice, so after individual data is fetched store is cleared
  // nuxtApp.$router.afterEach(() => {
  //   individualProductDataStore.$reset()
  // })
  watch(currentRoute, (newRoute, oldRoute) => {
    if (newRoute.path !== oldRoute.path) {
      individualProductDataStore.$reset()
    }
  }, { deep: true })

  // Watch on document visibility change in browser
  window.addEventListener('blur', async () => {
    userNavigatedUrl.value = null
    await verifySession()
  })

  /**
   * If customer data was added/removed from localStorage, reload page.
   * Thanks to this we can automatically refresh data in all tabs in browser.
   */
  window.addEventListener('storage', (event) => {
    if ((!event.newValue || !event.oldValue) && event.key === regionalStorageName('sectionData')) {
      window.location.reload()
    }
  })

  /**
   * If user navigated back/forward in browser,
   * remember this url to redirect correctly in verifySession()
   */
  window.addEventListener('popstate', (event) => {
    if (event?.state?.current) {
      userNavigatedUrl.value = event.state.current
    }
  })

  /**
   * Periodically check if customer session is still valid
   * and refresh token if it's about to expire
   */
  setInterval(() => verifySession(), 300000) // 5 minutes

  // Reloads initialData, pageData and navigation when user logs in/out
  watch(isLoggedIn, async (isLoggedInState) => {
    // fetch initialData for homepage, since there is no difference for subpages
    initialData.value = await getInitialData(getPathWithLocale())
    await initNavigation(initialData.value?.navigation)

    // update pageData for CMS pages or handle API redirect if needed
    if (currentRoute.value?.meta?.cmsPage && pageData.value) {
      const data = await getPage(currentRoute.value.fullPath)
      if (data) {
        if (data?.redirectUrl) {
          await navigateTo(data.redirectUrl, {
            redirectCode: data.statusCode,
          })
        }
        else {
          Object.assign(pageData.value, data)
        }
      }
    }

    // actions on logout
    if (!isLoggedInState) {
      // if user is not logged in and is on a page that requires auth, redirect to homepage
      const routeMiddlewares = currentRoute.value?.meta?.middleware ?? []
      if (Object.values(routeMiddlewares).includes('auth')) {
        await navigateTo(`/${currentLocale.value}`)
      }

      // clear individual data when user logs out
      individualProductDataStore.$reset()
    }
  })

  /**
   * Returns hours since passed timestamp
   * @param timestamp
   */
  function _hoursSince(timestamp: number): number {
    return Math.abs(Date.now() - timestamp) / 36e5 // 36e5 = 1000 * 60 * 60
  }

  toggleSystemNotificationOnCreditHold()
})
