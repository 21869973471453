import type { StoreConfigQuery } from '#gql'

export interface CentralOfficeData {
  storeName: string | null
  countryId: string | null
  regionId: string | null
  postcode: string | null
  city: string | null
  street: string | null
  vatNumber: string | null
  regon: string | null
}

export interface BusinessRole {
  label: string
  value: string
}

export interface CustomerServiceData {
  phoneNumber: string | null
  fax: string | null
  email: string | null
  helpline: {
    hours: {
      from: string | null
      to: string | null
    }
    days: string
  }
}

export interface SalesDirectorData {
  phoneNumber: string
}

export interface CurrentCurrencyData {
  currencyCode: string | null
  currencySymbol: string | null
}
export interface CurrencyData {
  currencyData: CurrentCurrencyData | null
}

export interface CheckoutData {
  maxCartRows: NonNullable<GqlStoreConfig>['rawlplug_catalog_max_cart_rows']
}

export const MAX_CART_ROWS_DEFAULT = '299'

export interface StoreConfigData {
  checkoutData: CheckoutData | null
  centralOfficeData: CentralOfficeData | null
  customerServiceData: CustomerServiceData | null
  businessRoles: Array<BusinessRole>
  // salesDirectorData: SalesDirectorData | null
  locale?: string | null | undefined
  [key: string]: unknown
}

export interface StoreConfigFetchingState {
  pending: boolean
  error: null | string
  [key: string]: unknown
}

/**
 * Consents types
 */
export type GqlStoreConfig = StoreConfigQuery['storeConfig']
export type GqlCurrency = StoreConfigQuery['currency']
export type GqlRawlplugConsents = StoreConfigQuery['rawlplugConsents']
export type ConsentsArea = 'customer_account' | 'sales_order' | 'marketing' | 'offers'
type ArrayElement<A> = A extends readonly (infer T)[] ? T : never
export type RawlplugConsentsObject = ArrayElement<
  StoreConfigQuery['rawlplugConsents']
>
export type RawlplugConsentsWrapper = NonNullable<
  ArrayElement<StoreConfigQuery['rawlplugConsents']>
>
export type RawlplugConsent = ArrayElement<RawlplugConsentsWrapper['consents']>

export interface StoreConfigState {
  storeConfig: StoreConfigData
  currency: CurrencyData
  consents: GqlRawlplugConsents
  _states: StoreConfigFetchingState
}
