import { storeToRefs } from 'pinia'
import { useCustomerStore } from '@customer/stores/customer'
import type { LogInFormValues } from '@customer/types/customer'
import { SystemRoleCodes } from '~/modules/rawlplug-ecom/constants/permissions'
import type { GetCustomerOrderQuery } from '#gql'

function useCustomer() {
  const customerStore = useCustomerStore()

  const { isLoggedIn, customerState, customerData, customerToken, isCheckoutEnabled }
    = storeToRefs(customerStore)
  const {
    logIn,
    logOutCustomer,
    resetPassword,
    resetState,
    refreshSectionData,
    getOrderInfo,
    refreshCustomerToken,
    resetCustomerData,
    _states,
    changePassword,
    getRequestToken,
  } = customerStore

  /**
   * Fetch category with passed params or get from state
   */
  const logInCustomer = async (
    url: string,
    userKey: string,
    passKey: string,
    tokenKey: string,
    values: LogInFormValues,
  ) => {
    return await logIn(url, userKey, passKey, tokenKey, values)
  }

  const getCustomerOrderInfo = async (
    orderNumber: string,
  ): Promise<GetCustomerOrderQuery | undefined> => {
    if (!orderNumber) {
      return
    }

    return await getOrderInfo(orderNumber)
  }

  const customerCompany = computed(() => {
    return customerData.value?.company
  })

  const customerRoleCode = computed(() => {
    return customerData.value?.acl?.role_code ?? undefined
  })

  const customerHasSystemRole = computed(() => {
    return SystemRoleCodes.includes(customerRoleCode.value as typeof SystemRoleCodes[number])
  })

  const adviserData = computed(() => customerData.value?.['adviser-data'])
  const formattedAdviserData = computed(() => ({
    phone: adviserData.value?.phone ?? '',
    email: adviserData.value?.email ?? '',
    fullname: `${adviserData.value?.first_name} ${adviserData.value?.last_name}`,
    position: adviserData.value?.title ?? '',
  }))
  const isAdvisorAssigned = computed(() => Boolean(adviserData.value?.email))

  return {
    logInCustomer,
    logOutCustomer,
    resetPassword,
    refreshSectionData,
    resetState,
    refreshCustomerToken,
    isLoggedIn,
    isCheckoutEnabled,
    customerState,
    customerData,
    customerToken,
    adviserData,
    formattedAdviserData,
    isAdvisorAssigned,
    getCustomerOrderInfo,
    resetCustomerData,
    _states,
    changePassword,
    getRequestToken,
    customerCompany,
    customerRoleCode,
    customerHasSystemRole,
  }
}

export { useCustomer }
