<template>
  <img
    v-if="displayAsImg && props.name"
    v-bind="$attrs"
    :src="iconUrl"
    alt=""
    :width="props.width"
    :height="props.height"
    loading="lazy"
    fetchPriority="low"
    :style="{ minWidth: props.width }"
  >
  <svg
    v-if="!displayAsImg && props.name"
    v-bind="$attrs"
    :width="props.width"
    :height="props.height"
    :data-icon="props.name"
    class="inline-block"
    :style="{ fill: props.fill, minWidth: props.width }"
  >
    <use
      :xlink:href="iconUrl"
      :href="iconUrl"
    />
  </svg>
</template>

<script lang="ts" setup>
interface UiIconProps {
  name: string | undefined
  width?: number | string
  height?: number | string
  fill?: string
  lazyImg?: boolean | undefined
}

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<UiIconProps>(), {
  width: 20,
  height: 20,
  fill: 'none',
  lazyImg: false,
})

const gradientIcons = [
  'circle-with-green-add-to-cart',
  'circle-with-green-bonus',
  'circle-with-green-file',
  'circle-with-orange-add-to-cart',
  'circle-with-orange-bonus',
  'circle-with-orange-file',
  'circle-with-purple-add-to-cart',
  'circle-with-purple-bonus',
  'circle-with-purple-file',
  'rawlplug-decoration-blue',
  'rawlplug-decoration-green',
  'rawlplug-decoration-orange',
]

const displayAsImg = computed(() => Boolean(props.lazyImg || gradientIcons.includes(props.name)))

const iconUrl = computed(() => {
  const assetsURL = globalThis?.__publicAssetsURL?.() ?? ''
  return `${assetsURL}/icons/${props.name}.svg${displayAsImg.value ? '' : '#icon'}`
})
</script>

<style lang="postcss">
svg,
use {
  fill: inherit;
}
</style>
